<template>
    <div class="text-xs bg-gray-800 tabs sub-tabs">
      <template v-for="tab in tabs">
        <a
          href="#"
          class="border-b-2 tab"
          :class="{
            'font-bold border-gray-700 rounded-t-sm': active === tab.type
          }"
          @click.prevent="active = tab.type"
        >
          <span class="text-gray-100">{{ tab.label }}</span>
        </a>
      </template>
        <CamerasPanel 
          v-if="active === 'cameras'"
          :gui="viewer.gui"
          @settings-changed="$emit('settings-changed', $event)"/>
    </div>
  </template>
  <script>
  const CamerasPanel = () =>
    import('@components/editor/scene/viewer/panels/CamerasPanel')
  
  export default {
    name: 'SettingsTab',
    props: {
      viewer: {
        type: Object,
        required: true
      }
    },
    components: {
      CamerasPanel
    },
    data() {
      return {
        tabs: [
          { type: 'cameras', label: 'Cameras' }
        ],
        active: 'cameras'
      }
    }
  }
  </script>
  